import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Load } from '@instech/icons';
import { BoxButton } from '@instech/components';
import { useCompanyContext } from '../CompanyContext';
import { useNavigate } from 'react-router-dom';
import { constants } from '../../../utils/constants';
import { useIndustries } from '../../../services/useIndustries';
import { handleSubmit } from './companyFormHandleSubmit';
import { ErrorMessage } from 'components/shared/datePicker/Components';
import { useAppContext } from '../../appRouting/AppContext';

const StyledLoad = styled(Load)`
  height: 20px;
`;
const Wrapper = styled.div`
  text-align: right;
  margin-top: 32px;
`;
const Item = styled.span`
  margin-left: 14px;
`;
export const CompanyFormButtons = ({
  dirty,
  isSubmitting,
  formState,
  handleReset,
  values,
  setSubmitting,
  formDispatch,
  mutateOrganizationRoles,
  notValidRoleType,
}) => {
  const {
    isNewRoleType,
    isRoleTypeEditable,
    isCoAssured,
    isNewCompany,
    appDispatch,
    vesselId,
    imo,
    fleetId,
    setIsNewCompanyRoleTypeOpen,
    setIsSearchCoAssuredOpen,
    setIsSearchAssuredOpen,
    mutateVesselList,
    roleId,
    organization,
    mutateFleetDetails,
    mutate: mutateOrganization,
    companyListMutate,
  } = useCompanyContext();

  const { data: industriesData } = useIndustries();

  let navigate = useNavigate();

  const { dispatch } = useAppContext();
  const setIsConfirmDialogVisible = (value) => {
    dispatch({ type: constants.SET_IS_CONFIRM_DIALOG_VISIBLE, value });
  }

  return (
    <Wrapper>
      <Item>
        {((dirty && !isSubmitting && !formState.isSaving) || (isRoleTypeEditable && isNewRoleType)) && (
          <BoxButton
            aria-label='Cancel'
            onClick={() => {
              appDispatch && appDispatch({ type: constants.SET_NEW_COMPANY, value: null });
              handleReset();
              mutateOrganization();
              setIsNewCompanyRoleTypeOpen && setIsNewCompanyRoleTypeOpen(false);
              isCoAssured && setIsSearchCoAssuredOpen && setIsSearchCoAssuredOpen(false);
              !isCoAssured && setIsSearchAssuredOpen && setIsSearchAssuredOpen(false);
            }}
            padding='10px 20px'
            title=''
            type='button'
            inverted
          >
            CANCEL
          </BoxButton>
        )}
      </Item>
      <Item>
        {formState.isSaving && !formState.isError ? (
          <StyledLoad />
        ) : (
          <BoxButton
            aria-label='Save'
            disabled={isSubmitting || (!dirty && !isNewRoleType) || notValidRoleType || formState.showRoleExistsMessage}
            onClick={() =>
              handleSubmit(
                values,
                setSubmitting,
                formDispatch,
                industriesData,
                isNewCompany,
                isCoAssured,
                appDispatch,
                navigate,
                vesselId,
                imo,
                fleetId,
                roleId,
                organization,
                mutateFleetDetails,
                mutateOrganizationRoles,
                isNewRoleType,
                setIsNewCompanyRoleTypeOpen,
                mutateVesselList,
                setIsSearchCoAssuredOpen,
                setIsSearchAssuredOpen,
                mutateOrganization,
                companyListMutate,
                setIsConfirmDialogVisible
              )
            }
            padding='10px 20px'
            title=''
            type='button'
          >
            SAVE
          </BoxButton>
        )}
        {formState.isError && isSubmitting && (
          <ErrorMessage>
            <div>Failed to save company data!</div>
            <div>{formState.errorMessage}</div>
          </ErrorMessage>
        )}
      </Item>
    </Wrapper>
  );
};

CompanyFormButtons.propTypes = {
  values: PropTypes.object,
  isSubmitting: PropTypes.bool,
  setIsSubmitting: PropTypes.func,
  formState: PropTypes.object,
  handleReset: PropTypes.func,
  formDispatch: PropTypes.func,
  mutateOrganizationRoles: PropTypes.func,
  initialValues: PropTypes.object,
  notValidRoleType: PropTypes.bool,
  dirty: PropTypes.bool,
};
