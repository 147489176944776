import { constants } from '../../../utils/constants';
import { CreateCompany, UpdateCompany } from '../../../services/postPutCompanyData';
import {
  dispatchSavingDone,
  logAndDispatchError,
  transformIndustries
} from "./companyFormUtils";
import { upsertOrganizationRole } from "./companyFormUpsertOrganizationRole";

export const handleSubmit = (
  values,
  setSubmitting,
  formDispatch,
  industriesData,
  isNewCompany,
  isCoAssured,
  appDispatch,
  navigate,
  vesselId,
  imo,
  fleetId,
  roleId,
  organization,
  mutateFleetDetails,
  mutateOrganizationRoles,
  isNewRoleType,
  setIsNewCompanyRoleTypeOpen,
  mutateVesselList,
  setIsSearchCoAssuredOpen,
  setIsSearchAssuredOpen,
  mutateOrganization,
  companyListMutate,
  setIsConfirmDialogVisible
) => {
  formDispatch({ type: constants.SET_INITIAL_ROLE_TYPE, value: values.clientRoleType });
  formDispatch({ type: constants.SET_IS_SAVING, value: true });
  setSubmitting(true);
  formDispatch({ type: constants.SET_IS_ERROR, value: false });
  formDispatch({ type: constants.SET_ERROR_MESSAGE, value: null });
  formDispatch({ type: constants.SET_SHOW_CONFIRM_REFRESH, value: false });
  if (!values.legalAddress.state?.id) values.legalAddress.state = null;
  if (!values.legalAddress.country.id) values.legalAddress.country.id = '00000000-0000-0000-0000-000000000000';

  const submittedValues = {
    id: values.id,
    organization: {
      ...values,
      industryCustomComment: undefined,
      industries: transformIndustries(values.industries, values.industryCustomComment, industriesData),
    },
  };

  const isOrganizationRoleUpsert = !!vesselId;

  if (isNewCompany) {
    createNewCompany(
      submittedValues,
      isCoAssured,
      setIsConfirmDialogVisible,
      formDispatch,
      appDispatch,
      navigate,
      vesselId,
      fleetId
    );
  } else if (isOrganizationRoleUpsert) {
    upsertOrganizationRole(
      isNewRoleType,
      fleetId,
      vesselId,
      imo,
      roleId,
      organization,
      submittedValues,
      values,
      isCoAssured,
      industriesData,
      setIsConfirmDialogVisible,
      formDispatch,
      appDispatch,
      navigate,
      setIsNewCompanyRoleTypeOpen,
      mutateVesselList,
      setIsSearchCoAssuredOpen,
      setIsSearchAssuredOpen,
      mutateOrganization,
      companyListMutate,
      mutateFleetDetails,
      mutateOrganizationRoles,
      setSubmitting
    );
  }
  else {
    updateOrganization(
      submittedValues,
      values,
      mutateFleetDetails,
      mutateOrganization,
      companyListMutate,
      setIsConfirmDialogVisible,
      setSubmitting,
      appDispatch,
      formDispatch
    );
  }
};

const updateOrganization = (
  submittedValues,
  values,
  mutateFleetDetails,
  mutateOrganization,
  companyListMutate,
  setIsConfirmDialogVisible,
  setSubmitting,
  appDispatch,
  formDispatch
) => {
  UpdateCompany(submittedValues, values._etag)
    .then(async (response) => {
      mutateFleetDetails && (await mutateFleetDetails());

      setIsConfirmDialogVisible(true);
      await mutateOrganization();

      companyListMutate && (await companyListMutate());
      appDispatch && appDispatch({ type: constants.SET_NEW_COMPANY, value: null });
      dispatchSavingDone(formDispatch);
    })
    .catch((error) => {
      logAndDispatchError(formDispatch, error)

      if (error.response?.status === 412) {
        setSubmitting(false);
        formDispatch({ type: constants.SET_SHOW_SAVE_CONFLICT, value: true });
      }
    })
}

const createNewCompany = (submittedValues, isCoAssured, setIsConfirmDialogVisible,
                          formDispatch, appDispatch, navigate, vesselId, fleetId) => {

  const roleType = {
    id: '',
    roleName: '',
    isCoAssured: isCoAssured,
  };

  CreateCompany(submittedValues)
    .then((response) => {
      const newCompany = {
        roleType,
        isActive: true,
        organization: {
          id: response.id,
          displayName: response.name,
          displayAddress: response.legalAddress.addressLine,
          ...response,
        },
      };
      setIsConfirmDialogVisible(true);
      dispatchSavingDone(formDispatch);
      appDispatch({ type: constants.SET_NEW_COMPANY, value: newCompany });
      setTimeout(() => {
        navigate(`/fleets/${fleetId}`, {
          state: {
            vesselId: vesselId,
            clientId: response.id,
            isNewRoleType: true,
            isCoAssured: isCoAssured,
            isNewCompany: true,
          },
          replace: true,
        });
      }, 2000);
    })
    .catch((error) => {
      logAndDispatchError(formDispatch, error)
    });
};
