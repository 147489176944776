import { constants } from "../../../utils/constants";
import { formatErrorMessage } from "../../../utils/errorMessageFormatter";

export const dispatchSavingDone = (formDispatch) => {
  formDispatch({ type: constants.SET_IS_SAVING, value: false });
  formDispatch({ type: constants.SET_SHOW_CONFIRM_REFRESH, value: false });
};

export const logAndDispatchError = (formDispatch, error) => {
  console.log(error);
  formDispatch({ type: constants.SET_ERROR_MESSAGE, value: formatErrorMessage(error) });
  formDispatch({ type: constants.SET_IS_ERROR, value: true });
  formDispatch({ type: constants.SET_IS_SAVING, value: false });
  formDispatch({ type: constants.SET_SHOW_CONFIRM_REFRESH, value: false });
};


// Transform industries form values to backend industry model.
// - Use matching industry model from API
// - Include comment in wrapper for industries where comments are enabled (only "Other" for now)
export const transformIndustries = (selectedIndustries, industryCustomComment, industriesData) => {
  return selectedIndustries.map(selected => {
    const industryData = industriesData.find(i => i.id == selected.value);
    return {
      industry: industryData,
      customComment: industryData?.isCustomCommentEnabled ? industryCustomComment : null,
    };
  });
};
