import { putJsonAsync, postJsonAsync } from './fetchConfig';

export const UpdateCompany = (values, etag) =>
  putJsonAsync(`organizations/${values.id}`, values, { headers: { 'If-Match': etag } });
export const CreateCompany = (values) => postJsonAsync('organizations', values);
export const ImportCompany = (values) => postJsonAsync('organizations/import', values);

export const CreateCompanyRole = (values) => postJsonAsync(`organizationroles`, values);
export const UpdateCompanyAndCompanyRole = (values, etag) =>
  putJsonAsync(`organizationroles`, values, { headers: { 'If-Match': etag } });
export const RemoveCompanyRole = (values, etag) =>
  postJsonAsync(`organizationroles/remove`, values, { headers: { 'If-Match': etag } });

// There is a timeout on Client in BE so can't go over 90s
export const AddCompanyRolesToObjects = (values) =>
  postJsonAsync(`organizationroles/batch`, values, { timeout: 90000 });

export const MarkExecutiveRolesComplete = (organizationId, executiveRolesCompleted, etag) => {
    const requestModel = { organizationId, executiveRolesCompleted };
    const config = {headers: {'If-Match': etag}};
    return putJsonAsync(`organizations/${organizationId}/personRoles/mark-complete`, requestModel, config);
};
