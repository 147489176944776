import {
  CreateCompanyRole,
  UpdateCompany,
  UpdateCompanyAndCompanyRole
} from "../../../services/postPutCompanyData";
import { constants } from "../../../utils/constants";
import {
  dispatchSavingDone,
  logAndDispatchError,
  transformIndustries
} from "./companyFormUtils";

export const upsertOrganizationRole = (
  isNewRoleType,
  fleetId,
  vesselId,
  imo,
  roleId,
  organization,
  submittedValues,
  values,
  isCoAssured,
  industriesData,
  setIsConfirmDialogVisible,
  formDispatch,
  appDispatch,
  navigate,
  setIsNewCompanyRoleTypeOpen,
  mutateVesselList,
  setIsSearchCoAssuredOpen,
  setIsSearchAssuredOpen,
  mutateOrganization,
  companyListMutate,
  mutateFleetDetails,
  mutateOrganizationRoles,
  setSubmitting
) => {
  if (isNewRoleType) {
    UpdateCompany(submittedValues, values._etag)
      .then(async (response) => {
        organization._etag = response._etag;
        mutateFleetDetails && (await mutateFleetDetails());

        const roleCommand = {
          fleetId: fleetId,
          objectId: vesselId,
          organizationRoleId: roleId,
          roleType: values.clientRoleType.roleType,
          isActive: true,
          activePeriod: values.activePeriod,
          organizationId: values.id,
          twoLetterCountryCode: values.legalAddress.country.partitionKey,
          ownerIsAssured: values.ownerIsAssured,
        };

        await CreateCompanyRole(roleCommand)
          .then(async () => {
            dispatchSavingDone(formDispatch);
            setIsNewCompanyRoleTypeOpen && setIsNewCompanyRoleTypeOpen(false);
            setTimeout(() => {
              navigate(`/fleets/${fleetId}`, {
                state: {
                  vesselId: vesselId,
                  isNewRoleType: false,
                },
                replace: true,
              });
            }, 2000);
            mutateFleetDetails && (await mutateFleetDetails());
            mutateVesselList && (await mutateVesselList());
            mutateOrganizationRoles && (await mutateOrganizationRoles());
            isCoAssured && setIsSearchCoAssuredOpen && setIsSearchCoAssuredOpen(false);
            !isCoAssured && setIsSearchAssuredOpen && setIsSearchAssuredOpen(false);
          })
          .catch((error) => {
            logAndDispatchError(formDispatch, error)
          });

        setIsConfirmDialogVisible(true);
        await mutateOrganization();

        companyListMutate && (await companyListMutate());
        appDispatch && appDispatch({ type: constants.SET_NEW_COMPANY, value: null });
        setIsNewCompanyRoleTypeOpen && setIsNewCompanyRoleTypeOpen(false);
      })
      .catch((error) => {
        logAndDispatchError(formDispatch, error)

        if (error.response?.status === 412) {
          setSubmitting(false);
          formDispatch({ type: constants.SET_SHOW_SAVE_CONFLICT, value: true });
        }
      });
  } else {

    const updateCommand = {
      organizationRoleId: roleId,
      activePeriod: values.activePeriod,
      ownerIsAssured: values.ownerIsAssured,
      fleetId: fleetId,
      organizationId: values.id,
      imo: imo,
      objectId: vesselId,
      organizationNumber: values.organizationNumber,
      lei: values.lei,
      name: values.name,
      legalAddress: values.legalAddress,
      legalForm: values.legalForm,
      vatNumber: values.vatNumber,
      fiscalNumber: values.fiscalNumber,
      industries: transformIndustries(values.industries, values.industryCustomComment, industriesData)
    };

    UpdateCompanyAndCompanyRole(updateCommand, organization._etag)
      .then(async () => {
        dispatchSavingDone(formDispatch);
        await mutateOrganization();
        setIsConfirmDialogVisible(true);
        mutateVesselList && (await mutateVesselList());
        mutateFleetDetails && (await mutateFleetDetails());
        mutateOrganizationRoles && (await mutateOrganizationRoles());
        setIsNewCompanyRoleTypeOpen && setIsNewCompanyRoleTypeOpen(false);
        setTimeout(() => {
          navigate(`/fleets/${fleetId}`, {
            state: {
              vesselId: vesselId,
            },
          });
        }, 2000);
      })
      .catch((error) => {
        logAndDispatchError(formDispatch, error)
      });
  }
}
